<template>
  <div class="personal-container flex ai_s pb32">

    <div class="content-wp flex center column">
      <!-- <p class="content-title">认证信息</p> -->
      <div class="content-form">
        <div class="auth-message">
          {{ getRealAuthText }}
        </div>
        <div @click="onAvatar" class="avatar pointer not-select mr30">
          <!-- <img v-if="!params.avatar" src="~@/assets/image/login/login_bg.png" /> -->
          <img v-if="params.avatar" :src="params.avatar" />
          <img v-else src="../../assets/image/global/head.png" />
          <p class="edit-btn flex ai_c">
            <i class="icon" />
          </p>
          <input @input="fileInput" ref="fileInput" accept="image/*" type="file" style="display: none;" />
        </div>
        <el-form :model="params" :rules="rules" ref="form" class="t-form" label-width="110px" label-position="left"
          size="medium" hide-required-asterisk>
          <div class="t-form-title flex ai_c">
            <i class="line"></i>基本资料
          </div>
          <el-form-item class="tr" label="姓	名" prop="username">
            <el-input v-model="params.username" />
          </el-form-item>
          <el-form-item label="性	别">
            <el-radio-group v-model="params.gender">
              <el-radio :label="1">男</el-radio>
              <el-radio :label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <div class="t-form-title flex ai_c">
            <i class="line"></i>执业信息
          </div>
          <el-form-item label="执业律所">
            <el-input v-model="params.lawOffice" />
          </el-form-item>
          <el-form-item label="执业/实习证号">
            <el-input v-model="params.lawCode" />
          </el-form-item>
          <el-form-item label="身份证号">
            <el-input v-model="params.idCard" />
          </el-form-item>
          <el-form-item label="绑定手机" prop="phone">
            <el-input v-model="params.phone" :disabled=canEditPhone oninput="value=value.replace(/[^\d]/g,'')"
              ng-pattern="/[^a-zA-Z]/" maxlength="11" />
          </el-form-item>
        </el-form>
      </div>
      <div class="content-foot flex jc_e">
        <t-btn @click="save" :disabled=canSave w="78px" h="36px" fs="14px" class="bold">保存</t-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { isMobile } from '@/assets/javascript/util'
import message from '@/mixins/message'

export default {
  mixins: [message],
  data() {
    const phone = (rule, value, callback) => {
      if (!isMobile(value)) {
        callback(new Error('请输入正确手机号'))
        return
      }
      callback()
    }
    return {
      realAuth: 0,
      canEditPhone: false,
      params: {
        username: '',
        phone: '',
        idCard: '',
        lawCode: '',
        lawOffice: '',
        gender: 0,
        avatar: '',
      },
      rules: {
        username: [
          { required: true, message: '请填写用户名称' }
        ],
        phone: [
          { required: true, message: '请填写绑定手机' },
          { validator: phone }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    authClass() {
      let style = { color: "" }
      if (this.realAuth == 3) {
        style.color = "#21E60F"
      } else {
        style.color = "#FF911C"
      }
      return style
    },
    getRealAuthText() {
      const texts = ["身份信息有误", "待审核", "审核通过", "审核未通过"]
      return "认证" + texts[this.realAuth - 1]
    },
    canSave() {
      let res = false;
      Object.entries(this.params).map(([key, val]) => {
        if (key !== 'avatar' && key !== 'lawCode') {
          if (val.length === 0) {
            res = true
          }
        }
      })
      return res
    }
  },
  watch: {
    userInfo(val) {
      this.init()
    }
  },
  methods: {
    ...mapMutations(['SETUSERINFO', 'SETAUTH']),
    // 初始化
    init() {
      const { params, userInfo } = this

      if (!userInfo) return

      Object.keys(params).forEach(v => {
        if (v == "phone") {
          if (userInfo[v] && userInfo[v].length > 0) {
            this.canEditPhone = true;
          }
        }
        this.params[v] = userInfo[v] || params[v]
      })
      this.realAuth = userInfo.realAuth || "0"
    },
    // 保存
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const { params } = this
          this.$api.system.editUserInfo(params).then(() => {
            this.success({
              message: '保存成功',
              duration: 1000
            })
            this.getUserInfo()
            // this.$router.push('/')
          })
        }
      })
    },
    // 获取个人信息
    getUserInfo() {
      this.$api.system.getUserInfo().then(res => {
        this.SETUSERINFO(res)
        if (res.realAuth !== 3) {
          this.SETAUTH(false);
        } else {
          this.SETAUTH(true);
          this.$router.push('/')
        }
      })
    },
    // 点击头像
    onAvatar() {
      this.$refs.fileInput.click()
    },
    fileInput(event) {
      const { target: { files } } = event

      this.upload(files[0])
    },
    async upload(file) {
      console.log('upload==', file)
      try {
        const res = await this.$api.system.getGlobalList({ type: 'ossAvatarSign' })

        const { ossAvatarSign: {
          accessid,
          host,
          policy,
          signature,
          path
        } } = res

        const fileName = file.name.split('.')
        const date = new Date().getTime()
        fileName[0] = `${fileName[0]}-${date}`

        let formData = new FormData
        formData.append('key', `${path}/${fileName.join('.')}`)
        formData.append('policy', policy)
        formData.append('OSSAccessKeyId', accessid)
        formData.append('signature', signature)
        formData.append('file', file)
        formData.append('success_action_status', 200)

        await this.$api.system.upload(host, formData)

        this.params.avatar = `${host}/${path}/${fileName.join('.')}`
        this.$refs.fileInput.value = ''
      } catch (err) {
        console.info(err)
      }
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.personal-container {
  height: 100%;
  background: #fff;
  padding: 24px;
  border-radius: 16px;

  .content-wp {
    width: 100%;
  }

  .avatar {
    position: relative;
    width: 72px;
    height: 72px;
    border: 2px solid #FFFFFF;
    overflow: hidden;
    margin: 0 auto;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: url('../../assets/image/global/mask.png') no-repeat;
      background-size: 100%;
      top: 0;
      left: 0;
      z-index: 1;
      opacity: 0.7;
      border-radius: 50%;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    .edit-btn {
      position: absolute;
      right: 2px;
      bottom: 2px;
      z-index: 2;

      .icon {
        width: 20px;
        height: 20px;
        background: url('../../assets/image/global/icon_edit2.png') no-repeat;
        background-size: 100%;
      }
    }
  }

  .content-form {
    width: 586px;
    background: rgba(247, 249, 251, 0.6);
    border-radius: 8px;
    padding: 32px 24px;
    margin-bottom: 32px;
    position: relative;

    .t-form {
      .t-form-title {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #60707D;
        line-height: 18px;
        margin-bottom: 22px;

        .line {
          width: 4px;
          height: 16px;
          background: #006AFF;
          border-radius: 2px;
          margin-right: 10px;
          display: inline-block;
        }
      }
    }

    .auth-message {
      min-width: 98px;
      height: 26px;
      background: #40B9A4;
      border-radius: 0px 8px 0px 8px;
      text-align: center;
      padding: 0 13px;
      font-weight: 500;
      color: #FFFFFF;
      font-size: 12px;
      position: absolute;
      right: 0;
      top: 0;
      line-height: 26px;
    }
  }

  .content-foot {
    width: 586px;

  }
}
</style>